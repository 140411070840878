.fade-in-opacity {
    opacity: 0;
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {
        font-size: 0.3rem;
        opacity: 0;
        display: block;
    }
    20% {
        font-size: 1rem;
        opacity: 1;
    }
    80% {
        font-size: 1rem;
        opacity: 1;
    }
    100% {
        font-size: 0.3rem;
        opacity: 0;
        display: none;
    }
}

@-moz-keyframes fadeIn {
    0% {
        font-size: 0.3rem;
        opacity: 0;
    }
    20% {
        font-size: 1rem;
        opacity: 1;
    }
    80% {
        font-size: 1rem;
        opacity: 1;
    }
    100% {
        font-size: 0.3rem;
        opacity: 0;
        display: none;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        font-size: 0.3rem;
        opacity: 0;
    }
    20% {
        font-size: 1rem;
        opacity: 1;
    }
    80% {
        font-size: 1rem;
        opacity: 1;
    }
    100% {
        font-size: 0.3rem;
        opacity: 0;
        display: none;
    }
}

@-o-keyframes fadeIn {
    0% {
        font-size: 0.3rem;
        opacity: 0;
    }
    20% {
        font-size: 1rem;
        opacity: 1;
    }
    80% {
        font-size: 1rem;
        opacity: 1;
    }
    100% {
        font-size: 0.3rem;
        opacity: 0;
        display: none;
    }
}

@-ms-keyframes fadeIn {
    0% {
        font-size: 0.3rem;
        opacity: 0;
    }
    20% {
        font-size: 1rem;
        opacity: 1;
    }
    80% {
        font-size: 1rem;
        opacity: 1;
    }
    100% {
        font-size: 0.3rem;
        opacity: 0;
        display: none;
    }
}
